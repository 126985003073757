export default class CommonUtil {
  formatToCurrency(val) {
    return val.toLocaleString();
  }
  zen2han(str) {
    if (!str) {
      return "";
    }
    return String(str).replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  }
  isFullWidth(src) {
    return String(src).match(/[Ａ-Ｚａ-ｚ０-９]/g) ? true : false;
  }
  han2zen(str) {
    if (!str) {
      return "";
    }
    return str.replace(/[A-Za-z0-9]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
    });
  }
  /**
   * 入力キーチェック
   */
  inputCheck(eventkey) {
    if (
      (eventkey >= 48 && eventkey <= 90) || // 0～z
      (eventkey >= 96 && eventkey <= 105) || // numPad 0～9
      eventkey == 189 || // -
      eventkey == 111 || // numPad -
      eventkey == 191 || // /
      eventkey == 109 || // numPad /
      eventkey == 32 // space
    ) {
      return true;
    }
    return false;
  }
  /**
   * 省略
   */
  omit(value, maxLength) {
    var val = value;
    if (val.length > maxLength) {
      val = val.substring(0, maxLength) + "...";
    }
    return val;
  }
  /**
   * ロット変換
   */
  convertLotNo(lotNoString) {
    let lot = lotNoString ? lotNoString.substring(0, 20).trimRight() : "";
    let serial = lotNoString ? lotNoString.substring(20, 52).trimRight() : "";
    let dueDate = lotNoString ? lotNoString.substring(52, 60).trimRight() : "";
    let lotSubkey1 = lotNoString ? lotNoString.substring(60, 80).trimRight() : "";
    let lotSubkey2 = lotNoString ? lotNoString.substring(80, 100).trimRight() : "";
    return {
      lot: (lot != "" ? lot : "-") + " ／ " + (serial != "" ? serial : "-"),
      dueDate:
        (dueDate != "" ? dueDate : "-") +
        " ／ " +
        (lotSubkey1 != "" ? lotSubkey1 : "-") +
        " ／ " +
        (lotSubkey2 != "" ? lotSubkey2 : "-"),
      original: {
        lot: lot,
        serial: serial,
        dueDate: dueDate,
        lotSubkey1: lotSubkey1,
        lotSubkey2: lotSubkey2,
      },
    };
  }
  /**
   * ロット作成
   */
  createLotNo(lot, serial, dueDate, lotSubKey1, lotSubKey2) {
    let lotStr = lot ? lot : "";
    let serialStr = serial ? serial : "";
    let dueDateStr = dueDate ? dueDate : "";
    let lotSubKey1Str = lotSubKey1 ? lotSubKey1 : "";
    let lotSubKey2Str = lotSubKey2 ? lotSubKey2 : "";
    return (
      lotStr.padEnd(20, " ") +
      serialStr.padEnd(32, " ") +
      dueDateStr.padEnd(8, " ") +
      lotSubKey1Str.padEnd(20, " ") +
      lotSubKey2Str.padEnd(20, " ")
    );
  }
  /**
   * 対象文字列が空でないかどうか判定
   *
   * @param {String} value 対象文字列
   * @returns {Boolean} 判定結果
   * ・true：値あり
   * ・false：空（空文字、NULL、undefined）
   */
  isNotBlank(value) {
    return value && value.length;
  }
  /**
   * 対象文字列が空かどうか判定
   *
   * @param {String} value 対象文字列
   * @returns {Boolean} 判定結果
   * ・true：空（空文字、NULL、undefined）
   * ・false：値あり
   */
  isBlank(value) {
    return !this.isNotBlank(value);
  }
  /**
   * 対象文字列に、文字列リストのいずれかが含まれているかを確認する
   * ・JavaのString.contains()と同じことをするイメージ
   *
   * @param {String} value 対象文字列
   * @param {Array} list 含まれているか確認する用の文字列リスト（複数可）
   * @returns {Boolean} 判定結果
   * listの文字列がtargetValueに
   * ・true：含まれる
   * ・false：含まれない
   *   ※targetValue：NULLまたは空文字
   *   ※list：配列ではない、またはサイズ0
   */
  contains(value, list) {
    // 対象文字列が空であれば早期リターン
    if (this.isBlank(value)) {
      return false;
    }

    // 文字列リストが配列で、サイズが1以上であれば
    // 文字列リストの内容が対象文字列に含まれるかの確認を行う
    if (Array.isArray(list) && list.length) {
      return list.some((element) => value.includes(element));
    }

    return false;
  }
  /**
   * 禁則文字[' "]を含むかチェックする
   * ・禁則文字が含まれる場合、エラーメッセージ返却
   *
   * @param {String} value - 値
   * @returns {Boolean} 判定結果
   * 禁則文字が
   * ・true：含まれる
   * ・false：含まれない
   */
  containsForbiddenCharacters(value) {
    const forbiddenCharacters = ["'", '"'];
    return this.contains(value, forbiddenCharacters);
  }
  /**
   * 入力値に全角文字が含まれるかチェックする
   *
   * @param {String} value - 値
   * @returns {Boolean} 判定結果
   * 全角文字が
   * ・true：含まれる
   * ・false：含まれない
   * ※補足：半角カナは全角ではないので、false
   */
  containsZenkakuCharacters(value) {
    // 対象文字列が空であれば早期リターン
    if (this.isBlank(value)) {
      return false;
    }

    return !String(value).match(/^[ -~ｦ-ﾟ]+$/g);
  }
}

export const commonUtil = new CommonUtil();
