import { i18n } from "../../../lang/lang.js";
import { appConfig } from "./AppConfig";

class MesssageUtil {
  /**
   * 指定のIDのメッセージを返します。
   * @param {String} messageId
   * @param {Array<String>} args
   * @returns {String}
   */
  getMessage(messageId, args) {
    const messageObj = this.getMessageObj(messageId);
    if (messageObj) {
      let message = messageObj.message;
      if (args) {
        args.forEach((arg, idx) => {
          message = message.replaceAll("{" + idx + "}", arg);
        });
      }
      return message;
    }
    return null;
  }
  /**
   * 指定のメッセージの埋め文字を埋める
   */
  fillMessagePadding(targetMessage, args) {
    let message = targetMessage ?? "";
    if (args) {
      args.forEach((arg, idx) => {
        message = message.replaceAll("{" + idx + "}", arg);
      });
    }
    return message;
  }
  getResMessage(resCom) {
    let message = "";
    if (resCom) {
      if (resCom.resComMessageId == null) {
        message = resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
      } else {
        message = i18n.tc("message.errorCode");
      }
    } else {
      message = i18n.tc("message.errorCode");
    }
    return message;
  }

  /**
   * 指定のIDのメッセージオブジェクトを返します。
   * @param {String} messageId
   * @returns {Json}
   */
  getMessageObj(messageId) {
    const searchRule = function (obj) {
      if (obj.id == messageId) {
        return true;
      }
      return false;
    };
    return this.messageList.find(searchRule);
  }

  messageList = [
    {
      id: "P-COM-001_001_E",
      level: "error",
      message: "認証できません。ユーザーID、またはパスワードが正しくありません。",
    },
    { id: "P-COM-001_002_E", level: "error", message: "{0}、{1}は必須です。" },
    {
      id: "P-COM-001_003_E",
      level: "error",
      message: "ログイン失敗可能回数の上限に達したため、ユーザーIDがロックされました。",
    },
    {
      id: "P-COM-001_003_W",
      level: "success",
      message: "セッションタイムアウトしています。再度ログインしてください。",
    },

    {
      id: "P-COM-001_004_W",
      level: "success",
      message: "編集内容を破棄してしまいますが、よろしいでしょうか。",
    },
    {
      id: "P-COM-001_005_C",
      level: "success",
      message: "{0}しました。",
    },
    {
      id: "P-COM-001_006_C",
      level: "success",
      message: "{0}してもよろしいでしょうか。",
    },
    {
      id: "A-999-999_001_I",
      level: "success",
      message: "{0}が完了しました。",
    },
    {
      id: "A-999-999_025_E",
      level: "success",
      message: "{0}に失敗しました。",
    },
    {
      id: "P-COM-001_007_E",
      level: "error",
      message: "{0}できませんでした。再度試してください。",
    },
    {
      id: "P-COM-001_008_C",
      level: "success",
      message: "{0}が操作中です。{0}に割込み処理を行って良いか確認してください。",
    },
    {
      id: "P-COM-001_008_E",
      level: "error",
      message: "{0}を入力した場合は{1}を入力してください。",
    },
    {
      id: "P-COM-001_009_E",
      level: "error",
      message:
        "{0}行目でカラム数が一致していないデータが混在しているため、{0}行目で処理を停止しています。",
    },
    {
      id: "P-COM-131_001_C",
      level: "success",
      message: "取引先を変更して登録する場合は、取引先を選択して連続登録ボタンを押してください。",
    },
    {
      id: "P-COM-131_002_C",
      level: "success",
      message: "下記関係先マスタを登録しました。",
    },
    {
      id: "P-999-999_999_E",
      level: "error",
      message: "APIを実行した際にシステムエラーが発生しました。",
    },
    {
      id: "A-999-999_999_E",
      level: "error",
      message: "APIを実行した際にシステムエラーが発生しました。",
    },
    {
      id: "A-999-999_001_E",
      level: "error",
      message: "APIを実行した際にシステムエラーが発生しました。",
    },
    {
      id: "P-999-999_999_E",
      level: "error",
      message: "APIを実行した際にシステムエラーが発生しました。",
    },
    {
      id: "P-RCV-001_001_C",
      level: "success",
      message: "登録しました。",
    },
    {
      id: "P-RCV-001_002_W",
      level: "error",
      message: "登録できませんでした。再度試してください。",
    },
    {
      id: "P-RCV-001_003_C",
      level: "success",
      message: "登録してもよろしいでしょうか。",
    },
    {
      id: "P-RCV-001_004_C",
      level: "success",
      message: "でよろしいでしょうか。",
    },
    {
      id: "P-RCV-001_005_E",
      level: "error",
      message: "入荷予定数が0の場合、登録できません。",
    },
    {
      id: "P-RCV-001_006_E",
      level: "error",
      message: "明細を登録してください。",
    },
    {
      id: "P-TOD-001_001_E",
      level: "error",
      message: "受注データを登録してください。",
    },
    {
      id: "P-RCV-001_007_E",
      level: "error",
      message: "個人宅情報の必須項目が入力されていません。",
    },
    {
      id: "P-RCV-004_001_C",
      level: "success",
      message: "伝票を削除してよろしいでしょうか。",
    },
    {
      id: "P-RCV-004_002_C",
      level: "success",
      message: "修正内容を登録してよろしいでしょうか。",
    },
    {
      id: "P-RCV-004_003_W",
      level: "success",
      message: "編集内容を破棄してしまいますが、よろしいでしょうか。",
    },
    {
      id: "P-EXT-005_001_C",
      level: "success",
      message: "引当取消します。よろしいでしょうか。",
    },
    {
      id: "P-EXT-005_002_C",
      level: "success",
      message: "引当取消しました。",
    },
    {
      id: "P-ENT-004_001_C",
      level: "success",
      message: "修正内容を登録してよろしいでしょうか。",
    },
    {
      id: "P-ENT-004_002_W",
      level: "success",
      message: "編集内容を破棄してしまいますが、よろしいでしょうか。",
    },
    {
      id: "P-SHP-012_001_C",
      level: "success",
      message: "取り込んだ出荷予定データをデータベースに登録しますが、よろしいでしょうか。",
    },
    {
      id: "P-SHP-004_001_C",
      level: "success",
      message: "修正内容を登録してよろしいでしょうか。",
    },
    {
      id: "P-SHP-004_002_W",
      level: "success",
      message: "編集内容を破棄してしまいますが、よろしいでしょうか。",
    },
    {
      id: "P-SHP-003_001_E",
      level: "success",
      message: "出荷予定数が0の場合、登録できません。",
    },
    {
      id: "P-SHP-003_002_E",
      level: "error",
      message: "荷札の発行権限がありません。",
    },
    {
      id: "P-SHP-010_001_C",
      level: "success",
      message: "路線フォーマットの登録が完了しました。",
    },
    {
      id: "P-SHP-010_001_E",
      level: "error",
      message: "変更点がありません。変更後、完了ボタンを押下してください。",
    },
    {
      id: "P-SHP-010_001_01-01",
      level: "error",
      message: "{0}.PC引当結果確認画面から引当取消して下さい",
    },
    {
      id: "P-SHP-010_001_01-02",
      level: "error",
      message: "{0}.PC出荷予定一覧画面から修正してください",
    },
    {
      id: "P-SHP-010_001_01-03",
      level: "error",
      message: "{0}.HT出庫取消をしてください",
    },
    {
      id: "P-SHP-010_001_01-04",
      level: "error",
      message: "{0}.HT出荷取消をしてください",
    },
    {
      id: "P-SHP-010_001_05",
      level: "error",
      message: "納品日が変更されています。\n取消作業を実施後、再度取込を実施してください。",
    },
    {
      id: "P-SHP-010_001_06",
      level: "error",
      message: "納品日が変更されています。\n明細を追加後、荷主に確認してください。",
    },
    {
      id: "P-SHP-010_001_08",
      level: "error",
      message: "納品日が変更されているため、\n明細が削除できませんでした。",
    },
    {
      id: "P-SHP-010_001_09",
      level: "error",
      message: "紐づいている伝票が作業中のため、\n納品日が変更できませんでした。",
    },
    {
      id: "P-SHP-010_001_99",
      level: "success",
      message: "更新中",
    },
    {
      id: "P-SHP-010_001_310",
      level: "success",
      message: "引当確定",
    },
    {
      id: "P-SHP-010_001_400",
      level: "success",
      message: "出庫済",
    },
    {
      id: "P-SHP-010_001_999",
      level: "success",
      message: "出荷済",
    },
    {
      id: "P-TMP-903_001_E",
      level: "error",
      message: "既に引当済のデータが含まれています。再チェックしてください。",
    },
    {
      id: "P-TMP-903_002_E",
      level: "error",
      message: "未引当のデータが含まれています。再チェックしてください。",
    },
    {
      id: "P-TMP-903_003_E",
      level: "error",
      message: "分解のデータが含まれています。再チェックしてください。",
    },
    {
      id: "P-TMP-909_001_C",
      level: "success",
      message: "取消処理が完了しました。",
    },
    {
      id: "P-TMP-903_003_E",
      level: "error",
      message: "チェックボックスに1つのみ、チェックをいれてください。",
    },
    {
      id: "P-TMP-903_004_E",
      level: "error",
      message: "予定数量分以上の在庫がありません。",
    },
    {
      id: "P-TMP-903_005_E",
      level: "error",
      message:
        "CSV出力できないデータが選択されています。出力済みが未または済の場合、CSV出力できます。",
    },
    {
      id: "P-MOV-002_001_E",
      level: "success",
      message: "移動前と異なるロケを選択してください。",
    },
    {
      id: "P-EXT-003_001_E",
      level: "error",
      message: "チェックボックスにチェックをいれてください。",
    },
    {
      id: "P-RCV-006_001_E",
      level: "error",
      message: "変更点がありません。変更後、登録ボタンを押下してください。",
    },
    {
      id: "P-RCV-006_003_E",
      level: "error",
      message:
        "品番：{0}、付帯品番：{1}は{2}の登録が必要です。\n入荷予定修正削除画面にて{2}を入力して再度実績を登録してください。",
    },
    {
      id: "P-RCV-006_004_E",
      level: "error",
      message:
        "品番：{0}、付帯品番：{1}は{2}の登録が不要です。\n入荷予定修正削除画面にて{2}を削除して再度実績を登録してください。",
    },
    {
      id: "P-RCV-007_001_C",
      level: "success",
      message: "取り込んだ入荷予定データをデータベースに登録しますが、よろしいでしょうか。",
    },
    {
      id: "P-MST-009_001_E",
      level: "success",
      message: "ロケーショングループを一括反映しますがよろしいでしょうか。",
    },
    {
      id: "P-MST-009_002_E",
      level: "success",
      message: "担当者を一括反映しますがよろしいでしょうか。",
    },
    {
      id: "P-MST-001_001_E",
      level: "error",
      message: "二行以上コピーはできません。一行のみ選択してください。",
    },
    {
      id: "P-MST-001_001_I",
      level: "success",
      message: "納品期限がある場合、日付を記入してください。",
    },
    {
      id: "P-MST-001_002_I",
      level: "success",
      message: "期限日を設定した場合、期限日を含む出荷期限の日数を記入してください。",
    },
    {
      id: "P-MST-001_003_I",
      level: "success",
      message:
        "納品期限日及び出荷期限日が近くなっている商品を在庫一覧で確認する為に設定する項目になります。\n期限日を起算日にして何日前から確認をするか設定ができます。",
    },
    {
      id: "P-MST-014_001_C",
      level: "success",
      message: "削除しました。",
    },
    {
      id: "P-MST-014_002_E",
      level: "error",
      message: "{0}の項目数に誤りがあります。",
    },
    {
      id: "P-EXT-001_001_E",
      level: "error",
      message: "チェックボックスに1つのみ、チェックをいれてください。",
    },
    {
      id: "P-MST-009_003_E",
      level: "error",
      message: `${appConfig.CONSTANT.BATCH}件を超える登録はできません。`,
    },
    {
      id: "P-MST-009_004_E",
      level: "error",
      message: `${appConfig.CONSTANT.LOCATIONMAXADD}件を超える登録はできません。`,
    },
    {
      id: "P-BIL-001_001_E",
      level: "sucess",
      message: "ギネスシステムへ連携しました。",
    },
    {
      id: "P-BIL-003_001_C",
      level: "sucess",
      message: "月締め処理を実行してもよろしいでしょうか。",
    },
    {
      id: "P-BIL-003_002_E",
      level: "sucess",
      message: "月締め処理の実行が完了しました。",
    },
    {
      id: "P-BIL-003_003_C",
      level: "sucess",
      message: "月締め処理を解除してもよろしいでしょうか。",
    },
    {
      id: "P-BIL-003_004_E",
      level: "sucess",
      message: "月締め処理の解除が完了しました。",
    },
    {
      id: "P-BIL-003_005_E",
      level: "error",
      message: "該当する実績データがありません。",
    },
    {
      id: "P-BIL-004_001_E",
      level: "error",
      message: "有効期限が設定された単価で単価×数量×単価係数が¥999,999,999を超過しています。",
    },
    {
      id: "P-BIL-005_001_E",
      level: "error",
      message: "変更点がありません。変更後、完了ボタンを押下してください。",
    },
    {
      id: "P-BIL-006_001_C",
      level: "sucess",
      message: "請求内訳書発行を実行してもよろしいでしょうか。",
    },
    {
      id: "P-MST-001_002_E",
      level: "error",
      message: "期間が重複しています。",
    },
    {
      id: "P-MST-001_003_E",
      level: "error",
      message: "有効終了日時が有効開始日時よりも未来の日付になっています。",
    },
    {
      id: "P-MST-001_004_E",
      level: "error",
      message: "ロケーションNoとロケーション名が重複しています。",
    },
    {
      id: "P-MST-001_005_E",
      level: "error",
      message: "ロケーションNoとロケーション名、または期間が重複しています。",
    },
    {
      id: "P-MST-001_006_E",
      level: "error",
      message: "「S/N有無」と「期限日有無」の両方を「有」にすることはできません。",
    },
    {
      id: "P-MST-001_007_E",
      level: "error",
      message: "期限日有無が有の場合、出荷期限日数は必須です。",
    },
    {
      id: "P-MST-001_008_E",
      level: "error",
      message: "期限切れ予告日数を入力する場合は納品期限日または出荷期限日数を入力してください。",
    },
    {
      id: "P-MST-001_009_E",
      level: "error",
      message: "出荷期限日数を入力する場合は期限日有無を有にしてください。",
    },
    {
      id: "A-999-999_004_E",
      level: "error",
      message: "移動後倉庫を選択してください。",
    },
    {
      id: "P-ENT-001_001_E",
      level: "error",
      message: "入庫済みのため、実績登録できません。",
    },
    {
      id: "P-CNT-006_001_E",
      level: "success",
      message: "承認してもよろしいでしょうか。",
    },
    {
      id: "P-ENT-002_001_E",
      level: "error",
      message: "実績数が指示数を超えているレコードがあります。",
    },
    {
      id: "P-ENT-002_003_E",
      level: "error",
      message: "実績数と指示数が一致していません。",
    },
    {
      id: "P-RTN-001_001_E",
      level: "error",
      message: "返品数量が0の場合、登録できません。",
    },
    {
      id: "P-RTN-001_002_E",
      level: "error",
      message: "返品数量が出荷実績数をこえているため、{0}できません。",
    },
    {
      id: "P-RTN-001_003_E",
      level: "error",
      message: "未承認データが存在する為、返品伝票を新しく登録することができません。",
    },
    {
      id: "P-ENT-002_002_E",
      level: "error",
      message: "ロケーションが選択されていません。",
    },
    {
      id: "P-CNT-006_002_C",
      level: "success",
      message: "承認しました。",
    },
    {
      id: "P-CNT-006_003_C",
      level: "error",
      message: "棚卸実績登録が完了していないため、承認できません。",
    },
    {
      id: "P-CNT-006_004_C",
      level: "error",
      message: "すでに承認済みです。",
    },
    {
      id: "P-CNT-006_005_E",
      level: "error",
      message: "ロケーションは{0}件を超えないように選択してください。",
    },
    {
      id: "P-RTN-002_001_E",
      level: "error",
      message: "返品理由区分が未選択の場合、登録できません。",
    },
    {
      id: "P-RTN-003_001_E",
      level: "success",
      message: "修正内容で承認してもよろしいでしょうか。",
    },
    {
      id: "P-RCV-006_002_E",
      level: "error",
      message: "正しい入力方法で登録してください。",
    },
    {
      id: "P-CNT-006_002_3",
      level: "success",
      message: "修正しました。",
    },
    {
      id: "P-STK-001_001_E",
      level: "error",
      message: "変更点がありません。変更後、確定ボタンを押下してください。",
    },
    {
      id: "P-STK-001_002_E",
      level: "error",
      message: "タイムアウトが発生しました。引当条件を変更するか、個別引当画面で実施してください。",
    },
    {
      id: "P-STK-001_003_E",
      level: "error",
      message:
        "引当確定時にタイムアウトが発生しました。\n引当結果確認画面で引当結果を確認し、未引当の場合は引当条件を変更するか、個別引当画面で実施してください。",
    },
    {
      id: "P-STK-001_004_E",
      level: "error",
      message: "タイムアウトが発生しました。個別引当画面で、引当件数を減らして実施してください。",
    },
    {
      id: "P-STK-001_005_E",
      level: "error",
      message:
        "引当確定時にタイムアウトが発生しました。\n引当結果確認画面で引当結果を確認し、未引当の場合は個別引当画面で、引当件数を減らして実施してください。",
    },
    {
      id: "P-STK-002_001_E",
      level: "error",
      message: "出庫済みのため、実績登録できません。",
    },
    {
      id: "P-STK-002_002_E",
      level: "error",
      message: "引当を取り消しますが、よろしいでしょうか。",
    },
    {
      id: "P-EXT-003_002_E",
      level: "error",
      message: "出荷済みのため、実績登録できません。",
    },
    {
      id: "P-MST-006_001_C",
      level: "success",
      message: "一括入力してもよろしいでしょうか。",
    },
    {
      id: "P-MST-006_001_E",
      level: "success",
      message: "行、列、高さに範囲指定を上回っている値があるため、一括入力できません。",
    },
    {
      id: "P-STK-002_003_E",
      level: "success",
      message: "引当確定してもよろしいでしょうか。",
    },
    {
      id: "P-STK-004_001_E",
      level: "error",
      message: "引当数が多いため、{0}件以下にしてください。",
    },
    {
      id: "P-MST-006_002_E",
      level: "success",
      message: "コピー対象を選択してください。",
    },
    {
      id: "P-SUP-001_001_E",
      level: "success",
      message: "再取込が完了しました。",
    },
    {
      id: "P-SUP-001_002_E",
      level: "success",
      message: "復元が完了しました。",
    },
    {
      id: "P-SUP-001_003_E",
      level: "error",
      message: "未取込の場合は搬入計画（CSV）を選択してください。",
    },
    {
      id: "P-SUP-020_001_C",
      level: "success",
      message: "計算が完了しました。検索ボタンを押してください。",
    },
    {
      id: "P-MST-006_003_E",
      level: "error",
      message: "1行以上残してください",
    },
    {
      id: "P-MST-049_001_E",
      level: "error",
      message: "ユーザー更新に失敗しました。",
    },
    {
      id: "P-MST-049_002_E",
      level: "error",
      message: "ユーザー更新中に異常終了しました。システム管理者へ問い合わせてください。",
    },
    {
      id: "P-MST-049_003_E",
      level: "error",
      message: "{0}を変更してもよろしいでしょうか。",
    },
    {
      id: "P-MNG-010_001_E",
      level: "error",
      message: "{0}以下のチェックのみ展開可能です。",
    },
    {
      id: "P-MNG-010_002_E",
      level: "error",
      message: "指示選択以外に1件以上入力してから{0}してください。",
    },
    {
      id: "P-STC-001_002_E",
      level: "error",
      message: "数量より小さい数値を入力してください。",
    },
    {
      id: "P-STC-001_003_E",
      level: "error",
      message: "変更前と変更後のステータスが同じです。",
    },
    // {
    //   id: "P-STC-001_004_E",
    //   level: "error",
    //   message: "選択されたデータは実績登録済みです。",
    // },
    {
      id: "P-STC-001_004_E",
      level: "error",
      message: "変更前と変更後のロットが同じです。",
    },
    {
      id: "P-STC-001_005_E",
      level: "error",
      message: "変更前と変更後の品番が同じです。",
    },
    {
      id: "P-CNT-004_001_E",
      level: "error",
      message: "未登録のデータがあります。登録後に完了を行ってください。",
    },
    {
      id: "P-WST-001_001_E",
      level: "error",
      message: "一度にアップロード出来るファイルサイズは{0}MBまでです。",
    },
    {
      id: "P-WST-001_002_E",
      level: "error",
      message: "ファイルのアップロードに失敗しました。再度、初めから登録処理を行ってください。",
    },
    {
      id: "P-WST-001_003_E",
      level: "error",
      message: "ファイルの削除に失敗しました。再度、削除処理を行ってください。",
    },
    {
      id: "P-WST-001_004_E",
      level: "error",
      message: "ファイルがアップロードされていません。",
    },
    {
      id: "P-STC-001_006_E",
      level: "error",
      message: "承認済みまたは廃棄、在庫調整以外のデータが含まれています。",
    },
    {
      id: "P-WST-001_005_E",
      level: "error",
      message: "詳細内容入力を入力してください。",
    },
    {
      id: "P-WST-001_006_E",
      level: "error",
      message:
        "ダウンロードリンクが無効になっています。再度ファイルダウンロード画面を開き直してください。",
    },
    {
      id: "P_SRG_004_001_E",
      level: "error",
      message: "出力できる在庫データが存在しません。",
    },
    {
      id: "P_SRG_005_001_E",
      level: "error",
      message: "ファイル内にデータが存在しません。アップロードファイルを確認してください。",
    },
    {
      id: "P-DST-001_001_C",
      level: "success",
      message: "完成指示を取消してもよろしいでしょうか。",
    },
    {
      id: "P-MST-013_001_E",
      level: "error",
      message: "変更点がありません。変更後、修正ボタンを押下してください。",
    },
    {
      id: "P-MST-013_002_E",
      level: "error",
      message: "ユーザーの登録に失敗しました。",
    },
    {
      id: "P-MST-013_003_E",
      level: "error",
      message: "ユーザーの登録中に異常終了しました。システム管理者へ問い合わせてください。",
    },
    {
      id: "P-SUP-002_001_E",
      level: "error",
      message: "登録する仕分情報を選択してください。",
    },
    {
      id: "P-SUP-002_002_E",
      level: "error",
      message: "1件以上出荷数量を入力してください。",
    },
    {
      id: "P-SUP-003_001_E",
      level: "error",
      message: "出庫済のため、修正できません。\nHTで取消の処置を行ってください。",
    },
    {
      id: "P-SUP-003_002_E",
      level: "error",
      message: "削除済のため、修正できません。",
    },
    {
      id: "P-SUP-017_001_E",
      level: "error",
      message: "ユーザー情報から1件選択してください。",
    },
    {
      id: "P-SUP-017_002_E",
      level: "error",
      message: "リンク情報を検索してください。",
    },
    {
      id: "P-SUP-017_003_E",
      level: "error",
      message: "追加するメーカー品番を選択してください。",
    },
    {
      id: "P-SUP-017_004_E",
      level: "error",
      message: "リンク情報に存在する品番が含まれています。",
    },
    {
      id: "P-SUP-017_005_E",
      level: "error",
      message: "リンク情報が選択されていません。",
    },
    {
      id: "P-SUP-017_006_E",
      level: "error",
      message: "未登録のリンク情報が選択されています。",
    },
    {
      id: "P-SUP-018_001_E",
      level: "error",
      message: "営業所が変更されています。登録してもよろしいですか。",
    },
    {
      id: "P-SUP-019_001_E",
      level: "error",
      message: "登録するユーザー品番情報が入力されていません。",
    },
    {
      id: "P-SUP-020_001_E",
      level: "error",
      message: "データが存在しないため、計算ボタンを押して集計してから再度検索してください。",
    },
    {
      id: "P-SUP-020_002_E",
      level: "error",
      message: "データが存在しないため、抽出条件を見直して再度検索してください。",
    },
    {
      id: "P-DST-001_002_E",
      level: "success",
      message: "完成指示の取消が完了しました。",
    },
    {
      id: "P-DST-001_003_C",
      level: "success",
      message: "完成指示を登録してもよろしいでしょうか。",
    },
    {
      id: "P-DST-001_004_S",
      level: "success",
      message: "伝票削除しますが、よろしいでしょうか。",
    },
    {
      id: "P-DST-001_005_E",
      level: "error",
      message: "資材数量が9桁以下になるように数量を修正してください。",
    },
    {
      id: "P-DST-002_001_E",
      level: "error",
      message: "{0}を入力してください",
    },
    {
      id: "P-DST-002_002_E",
      level: "error",
      message: "{0}を1以上で入力してください",
    },
    {
      id: "P-INI-001_001_E",
      level: "error",
      message: "取引先を選択してください。",
    },
    {
      id: "P-INI-003_001_E",
      level: "error",
      message: "削除するマスタを選択してください。",
    },
    {
      id: "P-INI-006_001_E",
      level: "error",
      message: "管理票残のみ削除はできません。\n削除は管理票を選択してください。",
    },
    {
      id: "P-INI-008_001_E",
      level: "error",
      message: "品番を選択してください。",
    },
    {
      id: "P-INI-008_002_E",
      level: "error",
      message: "パターンが重複しています。",
    },
    {
      id: "P-INI-008_003_E",
      level: "error",
      message: "順番を入力してください。",
    },
    {
      id: "P-INI-008_004_E",
      level: "error",
      message: "順番が重複しています。",
    },
    {
      id: "P-INI-008_005_E",
      level: "error",
      message: "作業工程パターンが未設定の品番が含まれています。",
    },
    {
      id: "P-INI-008_006_E",
      level: "error",
      message: "作業工程パターンが未設定です。",
    },
    {
      id: "P-INI-009_001_E",
      level: "error",
      message: "工程を選択してください。",
    },
    {
      id: "P-INI-009_002_E",
      level: "error",
      message: "作業手順が未登録です。",
    },
    {
      id: "P-INI-009_003_E",
      level: "error",
      message: "作業手順を検索してください。",
    },
    {
      id: "P-INI-009_004_E",
      level: "error",
      message: "頁を追加してください。",
    },
    {
      id: "P-INI-010_001_E",
      level: "error",
      message: "検査項目を検索してください。",
    },
    {
      id: "P-INI-010_002_E",
      level: "error",
      message: "検査項目が未登録です。",
    },
    {
      id: "P-INI-010_003_E",
      level: "error",
      message: "検査名を入力してください。",
    },
    {
      id: "P-INI-010_004_E",
      level: "error",
      message: "検査明細を1行以上入力してください。",
    },
    {
      id: "P-DVP-001_001_W",
      level: "success",
      message: "確定されている貨物・配車情報を変更しますがよろしいですか？",
    },
    {
      id: "P-DVP-001_002_W",
      level: "success",
      message: "確定されている貨物情報（便情報）を削除しますがよろしいですか？",
    },
    {
      id: "P-DVP-001_003_W",
      level: "success",
      message: "入力した納品日時は運行日より過去の日付となっています。",
    },
    {
      id: "P-DVP-001_004_W",
      level: "success",
      message: "期限が運行日より過去の日付の貨物情報が存在しますがよろしいですか？",
    },
    {
      id: "P-DVP-001_005_W",
      level: "success",
      message: "運行日を変更すると、登録が完了していない配車情報は失われますがよろしいですか？",
    },
    {
      id: "P-DVP-001_006_W",
      level: "success",
      message: "{0}の便情報を削除しますがよろしいですか？",
    },
    {
      id: "P-DVP-001_007_W",
      level: "success",
      message: "入力された時刻では、日付をまたいだ配車計画として登録されますがよろしいですか？",
    },
    {
      id: "P-DVP-001_001_C",
      level: "success",
      message: "便の削除が完了しました",
    },
    {
      id: "P-DVP-001_002_C",
      level: "success",
      message: "配車仮組み登録が完了しました",
    },
    {
      id: "P-DVP-001_001_E",
      level: "error",
      message: "乗務員を入力してください",
    },
    {
      id: "P-DVP-001_002_E",
      level: "error",
      message: "乗務員が未入力の便が存在します。乗務員を入力してください",
    },
    {
      id: "P-DVP-005_001_C",
      level: "success",
      message: "計画区分を「確定」に変更しますがよろしいでしょうか。",
    },
    {
      id: "P-DVP-005_002_S",
      level: "success",
      message: "計画区分を「確定」に変更しました。",
    },
    {
      id: "P-TOD-003_001_C",
      level: "success",
      message: "受注を削除してよろしいでしょうか。",
    },
    {
      id: "P-TRN-001_001_E",
      level: "error",
      message: "入力された番号は登録されていません。再度ご確認の上、もう一度お問い合わせ下さい。",
    },
    {
      id: "P-TRN-001_002_E",
      level: "error",
      message: "送り状番号を入力してください。",
    },
    {
      id: "P-TRN-001_003_E",
      level: "error",
      message: "お客様問い合わせ番号を入力してください。",
    },
  ];
}

export const messsageUtil = new MesssageUtil();
